import React from "react"
import { RichText } from "prismic-reactjs"

import htmlSerializer from "../../utils/htmlSerializer"

import Reveal from "react-reveal/Reveal"

const ContentBlock = ({ data }) => {
  return (
    <>
      {data.primary.text[0].text && (
        <Reveal effect="fadeInUp" fraction={0.05}>
          <section className={`grid-container`}>
            <div className={`grid-x`}>
              <div className={`cell`}>
                <RichText
                  render={data.primary.text}
                  htmlSerializer={htmlSerializer}
                />
              </div>
            </div>
          </section>
        </Reveal>
      )}
      {data.fields && data.fields.length > 0 && (
        <Reveal effect="fadeInUp" fraction={0.05}>
          <section className={`grid-container`}>
            <div className={`grid-x`}>
              <div className={`cell`}>
                {data.fields.map((img, index) => (
                  <img
                    key={index}
                    src={img.image.url}
                    alt={img.image.alt ? img.image.alt : ``}
                    className={`o-inline-img ${
                      img.image_width === "Full"
                        ? `u-img-full`
                        : img.image_width === "Half"
                        ? `u-img-half`
                        : ``
                    }`}
                  />
                ))}
              </div>
            </div>
          </section>
        </Reveal>
      )}
    </>
  )
}

export default ContentBlock
