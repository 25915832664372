import React from "react"
import { graphql } from "gatsby"

// import { RichText } from 'prismic-reactjs'

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import formatDate from "../utils/formatDate"
import PageLink from "../components/shared/pageLink"
import Image from "../components/shared/image"

import ContentBlock from "../components/widgets/contentBlock"
import ImageBlock from "../components/widgets/imageBlock"

import Reveal from "react-reveal/Reveal"

export const query = graphql`
  query BlogPostQuery($uid: String, $paginationNextUid: String!) {
    prismic {
      allBlog_lists {
        edges {
          node {
            page_heading
          }
        }
      }
      post: allBlog_posts(uid: $uid, sortBy: date_posted_DESC) {
        edges {
          node {
            _linkType
            _meta {
              id
              type
              uid
            }
            description_snippet
            main_image_description
            meta_image
            meta_imageSharp {
              publicURL
            }
            title
            date_posted
            author
            twitter_handle
            main_image
            main_imageSharp {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            body {
              ... on PRISMIC_Blog_postBodyText {
                type
                primary {
                  text
                }
              }
              ... on PRISMIC_Blog_postBodyBanner_with_caption {
                type
                primary {
                  image
                  caption
                  imageSharp {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      firstArticle: allBlog_posts(sortBy: date_posted_DESC, first: 1) {
        edges {
          node {
            _linkType
            title
            _meta {
              uid
              lang
              type
              id
            }
          }
        }
      }
      nextArticle: blog_post(uid: $paginationNextUid, lang: "en-ca") {
        _linkType
        title
        _meta {
          uid
          lang
          type
          id
        }
      }
    }
  }
`

const BlogPostTemplate = props => {
  const doc = props.data.prismic.post.edges.slice(0, 1).pop()
  if (!doc) return null

  const next =
    props.data.prismic.nextArticle ||
    props.data.prismic.firstArticle.edges.slice(0, 1).pop().node

  return (
    <Layout
      pageTitle={
        props.data.prismic.allBlog_lists.edges[0].node.page_heading[0].text
      }
      parentPage={`/insights`}
    >
      <SEO
        title={doc.node.title[0].text}
        image={doc.node.meta_image}
        description={doc.node.description_snippet || ``}
      />

      <article className={`s-blog`}>
        <Reveal effect="fadeInUp" fraction={0.05}>
          <section className={`grid-container c-banner`}>
            <div className={`grid-x align-justify c-banner__container`}>
              <div className={`cell medium-10`}>
                <div className={`c-banner__heading c-banner__heading--large`}>
                  <h1>{doc.node.title[0].text}</h1>
                  <span className={`u-text-caption`}>
                    {formatDate(doc.node.date_posted)}
                  </span>
                </div>
              </div>
            </div>
          </section>
        </Reveal>

        <Reveal effect="fadeInUp" fraction={0.05}>
          <section className={`grid-container`}>
            <div className={`grid-x`}>
              <div className={`cell`}>
                <figure>
                  <Image
                    sharpType="fluid"
                    sharpImage={doc.node.main_imageSharp.childImageSharp.fluid}
                    alt={doc.node.main_image.alt}
                    defaultImage={doc.node.main_image.url}
                  />
                  <figcaption className={`u-text-caption`}>
                    {doc.node.main_image_description}
                  </figcaption>
                </figure>
              </div>
            </div>
          </section>
        </Reveal>

        {doc.node.body && doc.node.body.length && (
          <section>
            <h2 className={`show-for-sr`}>Content</h2>
            {doc.node.body.map((section, index) => {
              switch (section.__typename) {
                case "PRISMIC_Blog_postBodyText":
                  return <ContentBlock key={index} data={section} />
                case "PRISMIC_Blog_postBodyBanner_with_caption":
                  return <ImageBlock key={index} data={section} />
                default:
                  return null
              }
            })}
          </section>
        )}

        <Reveal effect="fadeInUp" fraction={0.05}>
          <section className={`grid-container u-collapse-top`}>
            <div className={`grid-x`}>
              <div className={`cell`}>
                <p>
                  <strong>Written by {doc.node.author}</strong>
                </p>
                {doc.node.twitter_handle && (
                  <a
                    href={`//twitter.com/${doc.node.twitter_handle}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    @{doc.node.twitter_handle}
                  </a>
                )}
              </div>
            </div>
          </section>
        </Reveal>
      </article>

      {next && (
        <aside className={`c-aside grid-container`}>
          <div className={`c-aside__content grid-x`}>
            <Reveal effect="fadeInUp" fraction={0.05}>
              <div className={`cell`}>
                <PageLink className={`o-page-link`} item={next}>
                  <h2>
                    <span className={`u-text-caption o-page-link__cta`}>
                      Next article
                    </span>
                  </h2>
                  <article>
                    <h3>{next.title[0].text}</h3>
                  </article>
                </PageLink>
              </div>
            </Reveal>
          </div>
        </aside>
      )}
    </Layout>
  )
}

export default BlogPostTemplate
