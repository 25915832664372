	
// import { Date } from 'prismic-reactjs'

const formatDate = (date) => {
  const prismicDate = new Date(Date.parse(`${date}T00:00:00`))

  return Intl.DateTimeFormat('en-US',{
    year: 'numeric',
    month: 'long',
    day: 'numeric' }).format(prismicDate)
}

export default formatDate