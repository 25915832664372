import React from "react"
import Reveal from "react-reveal/Reveal"

import Image from "../shared/image"
const ContentBlock = ({ data }) => {
  return (
    <>
      <Reveal effect="fadeInUp" fraction={0.05}>
        <section className={`grid-container`}>
          <div className={`grid-x`}>
            <div className={`cell`}>
              <figure>
                <Image
                  sharpType="fluid"
                  sharpImage={data.primary.imageSharp.childImageSharp.fluid}
                  alt={data.primary.image.alt}
                  defaultImage={data.primary.image.url}
                />
                {data.primary.caption && (
                  <figcaption className={`u-text-caption`}>
                    {data.primary.caption}
                  </figcaption>
                )}
              </figure>
            </div>
          </div>
        </section>
      </Reveal>
    </>
  )
}

export default ContentBlock
