import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = ({ sharpImage, sharpType, background, defaultImage, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  
  if (sharpImage && background) {
    return <Img fluid={sharpImage} alt={alt ? alt : ``}
    imgStyle={{ objectFit: "cover" }}/>
  } if (sharpImage && sharpType === "fluid") {
    return <Img fluid={sharpImage} style={{ maxHeight: "100%" }} alt={alt ? alt : ``}
    imgStyle={{ objectFit: "contain" }}/>
  } else if (sharpImage && sharpType === "fixed") {
      return <Img fixed={sharpImage} alt={alt ? alt : ``} />
  } else if (defaultImage) {
    return <img src={defaultImage} alt={alt ? alt : ``} />
  } else {
    return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
  }
}

export default Image
